$big-design-disabled-color: rgb(217, 220, 233);

body {
    &.hasAdminBar {
        padding-top: 55px;

        .adminBar {
            display: block;
        }

        .navPages-container.is-open {
            padding-top: 55px;
        }

        .maintenanceNotice {
            display: none;
        }

        @media (min-width: 801px) {
            padding-top: 0;

            .banners {
                margin-top: 2.75rem;
            }
        }
    }
}

.adminBar {
    display: none !important;
    height: 0 !important;

    &.storefront {
        background-color: #fff;
        box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
        color: #626568;
        display: block !important;
        font-family: "Source Sans Pro", arial, "sans serif", sans-serif;
        font-size: 14px;
        font-weight: normal;
        height: 46px !important;
        left: 0;
        position: fixed;
        text-decoration: none;
        top: 0;
        width: 100%;
        z-index: 10000;
    }

    @media (min-width: 801px) {
        display: block;
    }

    .svg-icon {
        align-self: center;
        display: inline-flex;

        img {
            height: 1rem;
            width: 1rem;
        }

        &.svg-baseline img {
            position: relative;
            top: 0.125em;
        }
    }
}

.storefront {
    .adminBar-logo {
        background-color: transparent !important;
        float: left;
        height: 46px;
        width: 45px;

        img {
            height: 100%;
            padding: 7px 10px 11px 7px;
            width: 100%;
        }
    }

    .adminBar-content {
        align-items: center;
        display: flex;
        height: 46px;
        justify-content: space-between;

        a {
            color: #3e67f8;
            text-decoration: none;

            &:hover {
                background-color: #f0f3fe;
                border-radius: 5px;
            }
        }
    }

    .adminBar-private {
        margin-right: auto;
        text-align: left;

        .tooltip {
            position: relative;

            &:hover {
                .tooltiptext {
                    visibility: visible;
                }
            }

            .tooltiptext {
                background-color: #313440;
                border-radius: 6px;
                color: #fff;
                padding: 5px 10px;

                /* Position the tooltip text - see examples below! */
                position: absolute;
                text-align: center;
                visibility: hidden;
                width: auto;
                z-index: 1;
            }
        }

        .tooltip-bottom {
            left: 50%;
            margin-left: -3.75rem;
            top: 135%;
        }

        a {
            padding: 10px;
        }

        span {
            white-space: nowrap;
        }
    }

    .adminBar-links {
        margin-left: auto;
        padding-right: 5px;
        text-align: right;

        a {
            font-size: 14px;
            font-weight: 400;
            padding: 10px;
        }

        span {
            white-space: nowrap;
        }

        button.disabled {
            border: 1px solid $big-design-disabled-color;
            border-radius: 5px;
            color: $big-design-disabled-color;
            cursor: not-allowed;
            padding: 6px 10px;

            span {
                font-size: 14px;
                font-weight: 400;
            }

            img {
                filter: grayscale(1) opacity(0.25);
            }
        }
    }

    .adminBar-large {
        display: none;

        @media (min-width: 1261px) {
            display: initial;
        }
    }

    .adminBar-close {
        span {
            display: block;
            margin-bottom: 0.25rem;
            margin-right: 0.25rem;
            max-width: 40px;
            padding: 10px;

            img {
                text-align: center;
            }

            &:hover {
                background-color: #f0f3fe;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }

    .preview {
        font-weight: 400;
        padding-left: 9px;
    }
}
